import React from "react";
import Layout from "../components/layout";
import StandardHeader from "../assets/elements/StandardHeader";
import ThreeColumnUseCaseSection from "../assets/elements/ThreeColumnUseCaseSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import Cta from "../partials/Cta";
import { CASE_STUDIES } from "../partials/CaseStudyOverview";
import imgRetailWebm from "../assets/images/uses-retail.webm";
import imgDroneWebm from "../assets/images/uses-drone.webm";
import productKeypointWebm from "../assets/images/product-keypoint-2.webm";
import ThreeColumnFeatureSection from "../assets/elements/ThreeColumnFeatureSection";
import {
  ChartPieIcon,
  ChatIcon,
  CogIcon,
  CubeIcon,
  LightningBoltIcon,
  SparklesIcon,
} from "@heroicons/react/outline";
import CaseStudyVideo from "../partials/CaseStudyVideo";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import CustomLink from "../components/CustomLink";
import VideoComponent from "../components/VideoComponent";

const useCasesImageHeight = 198;
const useCasesImageWidth = 352;

const useCases = [
  {
    name: "Smart cities",
    description:
      "Local governments & cities are reducing traffic and pollution with analytics powered by computer vision.",
    bullets: [
      "Curbside monitoring",
      "Traffic management",
      "Public safety surveillance",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/uses-smart-cities.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Smart cities"}
      />
    ),
  },
  {
    name: "Sports analytics",
    description:
      "Sport teams are gaining a competitive edge in tournaments by discovering insights with AI.",
    bullets: [
      "Badminton pose adjustment",
      "Football team formation analytics",
      "Personalised coaching",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/uses-sports-pose.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Sports analytics"}
      />
    ),
  },
  {
    name: "Retail",
    description:
      "Retailers are using AI to locate items, gather intelligence on purchasing behaviour, and much more.",
    bullets: ["Automated checkouts", "Item detection", "Stock monitoring"],
    image: (
      <VideoComponent
        loop
        autoPlay
        muted
        playsinline
        width={useCasesImageWidth}
        height={useCasesImageHeight}
      >
        <source src={imgRetailWebm} type="video/webm" />
      </VideoComponent>
    ),
  },
  {
    name: "Agriculture",
    description:
      "Farmers use robotic crop harvesting and seasonal yield diagnostics to improve efficiency and reduce wastage.",
    bullets: [
      "Self-driving tractors",
      "Autonomous harvesters",
      "Livestock surveillance",
    ],
    image: (
      <VideoComponent
        loop
        autoPlay
        muted
        playsinline
        width={useCasesImageWidth}
        height={useCasesImageHeight}
      >
        <source src={imgDroneWebm} type="video/webm" />
      </VideoComponent>
    ),
  },
  {
    name: "Autonomous transportation",
    description:
      "Autonomous vehicles are poised to revolutionise the way societies are structured.",
    bullets: [
      "Long-haul autonomous trucking",
      "Driverless taxi services",
      "Self-flying planes",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/uses-av.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Autonomous vehicle"}
      />
    ),
  },
  {
    name: "AR/VR",
    description:
      "The meta-verse is here and is already transforming our social lives with gaming.",
    bullets: [
      "Human pose estimation",
      "MMORPG games",
      "Blending digital and analogue worlds",
    ],
    image: (
      <VideoComponent
        loop
        autoPlay
        muted
        playsinline
        width={useCasesImageWidth}
        height={useCasesImageHeight}
      >
        <source src={productKeypointWebm} type="video/webm" />
      </VideoComponent>
    ),
  },
];

const features = [
  {
    name: "Flexible tools",
    description:
      "Our software supports a wide variety of computer vision modalities. Classify & annotate bounding boxes, polygons, key points, and segments in a single editor.",
    icon: CubeIcon,
  },
  {
    name: "Model-assisted labeling",
    description:
      "Use our native micro-model technology to reduce the manual annotation burden and pivot human supervision from labeling to quality control.",
    icon: LightningBoltIcon,
  },
  {
    name: "Configurable label editor",
    description:
      "Set up your own label structures with infinitely nested attributes and hierarchical relationships. Apply nested classifications and preserve conditional relationships between features.",
    icon: CogIcon,
  },
  {
    name: "Quality",
    description:
      "Create custom annotation & review pipelines with our intuitive interface. Discover poorly performing annotators using our performance dashboards, benchmark & consensus features.",
    icon: SparklesIcon,
  },
  {
    name: "Collaboration made easy",
    description:
      "Role-based access control, annotator performance tracking, and dynamic task queues make massive-scale labeling operations a bliss.",
    icon: ChatIcon,
  },
  {
    name: "Visualise",
    description:
      "Reduce time to production by spotting data biases and imbalances early. Discover & visualise errors in your datasets.",
    icon: ChartPieIcon,
  },
];

const ComputerVision = ({ location }) => {
  return (
    <Layout location={location}>
      {/*  Page illustration */}
      <LeftColours />
      <RightColours />

      <StandardHeader
        title={"Collaborative Computer Vision Tools for Teams"}
        description={
          "Our software is designed to handle the varying requirements and modalities of visual data."
        }
      />

      {/* Use cases */}
      <ThreeColumnUseCaseSection
        caseStudy={CASE_STUDIES.MOT}
        title={"Use cases"}
        header={"Consolidated computer vision workflows "}
        description={
          <>
            Our suite of powerful tools allows for seamless collaboration across
            roles and teams, from domain-expert annotators to project managers
            and machine learning engineers. Learn more about our{" "}
            <CustomLink
              to={"/image/"}
              className="text-gray-700 transition duration-150 ease-in-out hover:text-gray-900"
            >
              image
            </CustomLink>
            ,{" "}
            <CustomLink
              to={"/video/"}
              className="text-gray-700 transition duration-150 ease-in-out hover:text-gray-900"
            >
              video
            </CustomLink>
            , and{" "}
            <CustomLink
              to={"/dicom/"}
              className="text-gray-700 transition duration-150 ease-in-out hover:text-gray-900"
            >
              DICOM
            </CustomLink>{" "}
            tools.
          </>
        }
        useCases={useCases}
      />

      {/* Three column feature section */}
      <ThreeColumnFeatureSection
        title={"How it works"}
        header={"Annotation, training, deployment"}
        description={
          "Power models from segmentation to pose detection using training data generated through Encord."
        }
        features={features}
      />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={false}
        title={"Invest"}
        header={"Our investors 2"}
        description={"Our investors"}
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../assets/images/flower-segmentation.png"}
            width={900}
            alt={"Automate with micro-models"}
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../assets/images/quality-pred-iou-grid-in-program.png"}
            width={550}
            alt={"Understand label quality"}
          />
        }
        headerOne={"Automate with micro-models"}
        headerTwo={"Understand label quality"}
        descriptionOne={
          "Augment your human workforce with our novel micro-model technology and flexible labeling tools."
        }
        descriptionTwo={
          "Use our automated quality control features to ensure only the best ground truth is delivered to your models."
        }
      />

      <CaseStudyVideo />

      <br />

      <Cta
        location={location}
        pageName={"Computer Vision Page"}
        ctaText={"Train computer vision models faster with Encord"}
      />
    </Layout>
  );
};

export default ComputerVision;

export const Head = () => (
  <SEO
    title="The Training Data Platform for Computer Vision AI | Encord"
    description="Encord's powerful tools make creating training data from videos and images quicker and easier."
  />
);
